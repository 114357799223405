import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import Updates from "../components/Blog/Updates";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div``;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <News />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/blogs`);
};
const fetchTag = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/news-page`);
};
function News({ data }) {
  const [newData, setNewData] = useState(null);
  const [newMetaData, setNewMetaData] = useState(null);
  const [newsTag, setNewsTag] = useState(null);
  const { data: pageData } = useQuery("news", fetchData);
  const { data: tag } = useQuery("tags", fetchTag);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (tag !== undefined) {
      setNewMetaData(tag.data)
      setNewsTag(tag.data.HandleSelect);
    }
  }, [pageData, tag]);
  return (
    <Layout>
      {newData === null && <Loader />}
      <StyledWrapper className="pt-5 padding-left-8 padding-right-8 ">
        {newData && <SEO title={newMetaData.SEOtitle} description={newMetaData.SEOdescription} />}
        {newData && <h1>LATEST</h1>}
        {newData && newsTag && <Updates data={newData} tag={newsTag} />}
      </StyledWrapper>
    </Layout>
  );
}
