import React, { useState, useEffect } from "react";
import styled from "styled-components";
import arrow from "../../images/news_page_arrow.svg";
import { Link } from "gatsby";
import axios from "axios";

const StyledWrapper = styled.div`
  .team-categories {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: ${props => props.theme["zero"]};
    line-height: ${props => props.theme["footerLinkLineHeight"]};
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: ${props => props.theme["externalLinkSize"]} !important;
    }
  }
  .active,
  .team-categories:hover .border-on-hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 3px solid ${props => props.theme["borderBottomColor"]};
  }

  .blog-image {
    max-height: 100%;
    width: 100%;
    @media (max-width: 750px) {
      height: 100%;
      width: 100%;
    }
    @media (max-width: 950px) {
      padding-bottom: 4px;
    }
  }

  .news-desc {
    font-size: 24px;
    font-weight: bold;
    width: 870px;
    @media (max-width: 991px) {
      padding-bottom: 10px;
    }

    @media (max-width: 1440px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (min-width: 1441px) {
      width: 100%;
    }
  }

  .news-date {
    font-size: 18px;
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-top: 10px;
    }
    text-align: end;
    @media (max-width: 991px) {
      text-align: start;
      padding-left: 0px;
    }
  }

  .news-author {
    font-size: 17px;
    font-weight: 400;
  }

  .border-bot {
    border-bottom: 3px solid #9ea5ee;
    padding-bottom: 15px;
    line-height: 27px;
    padding-top: 29px;
  }

  .news-info {
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-top: 10px;
    }
  }

  .desc-block {
    width: 870px;
    padding-left: 10px;
    @media (max-width: 991px) {
      padding-left: 0px;
    }
  }

  .arrow-class {
    text-align: end;
    @media (min-width: 991px) {
      padding-right: 0px;
    }
  }

  .link-decoration {
    text-decoration: none;
    color: black;
  }
`;

// const Blog = [
//   { title: "Press Release", handle: "pressrelease" },
//   { title: "Upreach in the News", handle: "upreach" },
//   { title: "Social Mobility News", handle: "socialmobility" },
//   { title: "Research", handle: "research" },
//   { title: "Blog", handle: "blog" },
//   { title: "UPREACH REPORTS", handle: "" },
// ];

export default function Updates({ data, tag }) {
  const [showNews, setShowNews] = useState(true);

  const Blog = [];
  if (tag.length > 0) {
    tag
      .sort((a, b) => a.orderNo - b.orderNo)
      .map(item =>
        Blog.push({
          news: item.newsName,
          handle: item.newsHandle,
          showNews: item.showNews,
        })
      );
  }
  const [selectedTabByHandle, setSelectedTabByHandle] = useState(
    "pressrelease"
  );
  const unique = [...new Set(data.map(i => i.category))];
  const [browserName, SetBrowserName] = useState("");

  // useEffect(()=>{
  //     if(window){
  //         setSelectedTabByHandle(window.location.hash.split("#")[1])
  //     }
  // },[])
  useEffect(() => {
    let userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      SetBrowserName("chrome");
    } else if (userAgent.match(/firefox|fxios/i)) {
      SetBrowserName("firefox");
    } else if (userAgent.match(/safari/i)) {
      SetBrowserName("safari");
    } else if (userAgent.match(/opr\//i)) {
      SetBrowserName("opera");
    } else if (userAgent.match(/edg/i)) {
      SetBrowserName("edge");
    } else {
      SetBrowserName("No browser detection");
    }
  }, []);

  const getBlogDate = blogDate => {
    if (blogDate === null || blogDate.length === 0) {
      return;
    }

    var monthObj = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    var date = blogDate.split(" ")[0].slice(0, -2);
    if (date.length === 1) {
      date = "0" + date;
    }
    var month = blogDate.split(" ")[1];
    var year = blogDate.split(" ")[2];
    if (browserName === "safari") {
      return new Date(month + "-" + date + "-" + year);
    } else {
      return new Date(month + "/" + date + "/" + year);
    }
  };
  const mainUrl = process.env.GATSBY_API_URL;

  return (
    <StyledWrapper>
      <div className="container-fluid mt-3">
        <div className="row">
          {Blog.map(
            item =>
              item.showNews === true && (
                <div className="pr-3">
                  <p
                    className="team-categories mt-2 mt-md-4"
                    onClick={() => {
                      window.location.hash = item.handle;
                      setSelectedTabByHandle(item.handle);
                      setShowNews(item.showNews);
                    }}
                  >
                    <span
                      className={
                        selectedTabByHandle === item.handle
                          ? "border-on-hover active"
                          : "border-on-hover"
                      }
                    >
                      {item.news}
                    </span>
                  </p>
                </div>
              )
          )}
        </div>
        <div className="row">
          {showNews &&
            data
              .filter(item => item.category === selectedTabByHandle)
              .sort((a, b) => getBlogDate(b.date) - getBlogDate(a.date))
              .map(item => (
                <div className="col-12 border-bot">
                  <div className="row">
                    {item.Image !== null ? (
                      <div className="col-lg-2 col-sm-12 px-0">
                        <img
                          className="blog-image img-fluid"
                          src={mainUrl + item.Image.url}
                          alt=""
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-lg-7 col-sm-12 d-flex flex-column desc-block">
                      <a
                        target={
                          item.ShowInExternalPage === true ? "_blank" : "_self"
                        }
                        className="link-decoration"
                        href={"/news/" + item.handle}
                      >
                        <div className="news-desc">{item.title}</div>
                      </a>

                      <div className="news-author">{item.author}</div>
                    </div>
                    <div className="col-lg-2 col-6 news-date">{item.date}</div>
                    <div
                      className={`${
                        item.Image !== null
                          ? "col-lg-1 col-6 arrow-class"
                          : "col-lg-3 col-6 arrow-class"
                      }`}
                    >
                      <a
                        target={
                          item.ShowInExternalPage === true ? "_blank" : "_self"
                        }
                        href={"/news/" + item.handle}
                      >
                        <img src={arrow} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </StyledWrapper>
  );
}
